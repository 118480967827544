import React from "react";
import "./quote.css"; 
import Profilepic from "./profilepics/christian.jpg";

const Quote = () => {
    return (
        <div className="container-fluid">
        <div className="row  justify-content-center d-flex">
       
        <div className="col-12">
              <h1 className='title-f'>Contact Us </h1>
        </div>

      </div>



{/*---------------------------------------------------------------------first personel -----------------------------------------------*/ }
      <div className="row py-1 d-flex">
            <div className="col-md-1"></div>

            
                
            

            <div className="col-md-6 contactus-container flex d-flex">
                <div className=" ex-con contactus-container ">
             <img src={Profilepic} className="prof-pic" alt="Christian Drake" />
                </div>
                <div className="container-per">
                    <h4><span className="personell-title">CEO:</span> Christian Drake</h4>
                    <p className="par-text">I am the CEO and Owner of TheRightTouch handyman Services, I studied at lander university for software development, and took courses on buisness management.
                        Also i have worked many Commercial and residential maintenance positions over the past 9 years.
                        Currently working towards a general contractors licence and other specialty licences.</p>
                </div>
            </div>


                
            

            <div className="col-md-1"></div>
        </div>
{/*---------------------------------------------------------------------first personel -----------------------------------------------*/ }



{/*---------------------------------------------------------------------second personel -----------------------------------------------*/ }


        <div className="row py-1 d-flex">
            <div className="col-md-1">  </div>

            <div className="col-md-6 contactus-container flex d-flex move-right">
                <div className=" ex-con contactus-container ">
                 <img src={""} className="prof-pic" alt="Russell Drake" />
                </div>
                
                <div className="container-per">
                    <h4 className="title-col"><span className="personell-title">CTO:</span> Russell Drake</h4>
                    <p className="par-text">I have owned the right touch handyman services for about 15 years, i currently still do work on some projects but not as much as i used to. 
                        I have much experience in Drywall, Roofing, Flooring, Plumbing, Electrical, and manufacturing experience.  </p>
                </div>
            </div>

                
            

            <div className="col-md-1"></div>
        </div>
        {/*---------------------------------------------------------------------second personel -----------------------------------------------*/ }


            <div className="row py-1 d-flex ">
                    <div className="col-md-3"></div>
                    <div className="col-md-6  container container-fit center-text">
                        <h2>How to get in touch with us</h2>
                        <p> CALL: 864-323-1531</p>
                        <p> CALL: secondary: 864-209-7164</p>
                        <p> <span className="underlined-bold">FILL OUT OUR FORM:</span> <a href="/quote">Quote Form</a></p>
                        <p>EMAIL: therighttouch.homerepair@gmail.com</p>
                    </div>
                    <div className="col-md-3"></div>
            </div>
          </div>
    );
};

export default Quote;