import React from 'react';
import "./privacypolicy.css";

const PrivacyPolicy = () => {
    // Add your component logic and JSX content here
    return (
      <div className='margin-space bor-change'>
                    {/*Page Title */}
                    <div className="row justify-content-center d-flex ">
                        <div className="col-12">
                            <h1 className='title-f'>Privacy Policy</h1>
                        </div>
                    </div>
                    {/*Page Content */}
                    <div className="row justify-content-center d-flex">
                    <div className="col-12">
                        <p className='text-left'>Privacy Policy
TheRightTouch LLC is committed to protecting the privacy of our customers. This Privacy Policy outlines how we collect, use, and protect the personal information you provide to us.   
<br></br><br></br>
<h2>Information We Collect</h2>
<br></br><br></br>
We may collect the following information when you contact us or use our website:
<br></br><br></br>
<span classname="text-bold">Name: </span>This information is necessary for us to identify you and communicate with you.<br></br>
<span classname="text-bold">Phone number:</span> This information is used to contact you regarding your project or quote.<br></br>
<span classname="text-bold">Email address:</span> This information is used to communicate with you regarding your project or quote.<br></br>
<span classname="text-bold">Project details:</span> This information is necessary to provide you with an accurate quote or estimate.<br></br><br></br>
Contract or quote information: This information is used to document our agreement with you.<br></br><br></br>
<h2>How We Use Your Information</h2><br></br>
We use your information solely for the purpose of conducting business with you. This includes:<br></br><br></br>

Providing quotes or estimates for your project.<br></br>
Communicating with you regarding your project.<br></br>
Fulfilling contractual obligations.<br></br>
<h2>Information Sharing</h2><br></br>
We do not share, sell, or rent your personal information to any third parties for marketing or other purposes.<br></br>
<br></br>
<h2>Data Security</h2><br></br>
We take reasonable measures to protect your personal information from unauthorized access, use, or disclosure. However, no method of transmission over the internet or electronic storage is completely secure.   
<br></br><br></br>
<h2>Your Rights</h2><br></br>
You have the right to access, correct, or delete your personal information. If you have any questions or concerns about our privacy policy, please contact us at [Your Email Address].   
<br></br><br></br>
Please note: This is a basic privacy policy template and may not cover all legal requirements. It is strongly recommended that you consult with an attorney to ensure compliance with applicable laws and regulations in your jurisdiction.
<br></br><br></br>
Disclaimer: The information provided here is intended for general knowledge and informational purposes only, and does not constitute legal advice.</p>
<br></br><br></br>      
                      </div>
                    </div>
                   
      </div>
      );
      
      
};
export default PrivacyPolicy;