
import React, {useState} from 'react';
import "./contact.css";

import { createClient } from '@supabase/supabase-js'

const supabaseUrl = "https://xkpmsceqwrlvjszrqotn.supabase.co";
const supabaseKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InhrcG1zY2Vxd3JsdmpzenJxb3RuIiwicm9sZSI6InNlcnZpY2Vfcm9sZSIsImlhdCI6MTcxNzcyNzA1NCwiZXhwIjoyMDMzMzAzMDU0fQ.iXEpMFQTqPORn9lq9JvmoUb62gsEaEUtsysrmSY3wtQ";
const supabase = createClient(supabaseUrl, supabaseKey)


const Contact =() => {


        const [formData, setFormData] = useState({
          first: '',
          last: '',
          email: '',
          phone: '',
          proposal_text: '',
          mbudget: '',
          lbudget: '',
          
        });
        /*
        
        const handleCheck = (event) => {
        const contactMethod = document.querySelector('input[name="contact_method"]:checked');

        let preferredContact;

          if (contactMethod) {
            const selectedValue = contactMethod.value;
            console.log(`Selected contact method: ${selectedValue}`);
        
            // You can now set a variable based on the selected value
            
            if (selectedValue === 'phone_text') {
              preferredContact = 'phone_text';
              if (selectedValue === 'email'){
                preferredContact = 'Both';
              }
            } else if (selectedValue === 'email') {
              preferredContact = 'email';
            }else {
              preferredContact = 'None Selected';
            }
            setPreference(preferredContact)

            
          }
        }
          */
 
      
      
      
        const handleChange = (event) => {
          
          setFormData({ ...formData, [event.target.name]: event.target.value});
        };
      
        const handleSubmit = async (event) => {
          event.preventDefault(); // Prevent default form submission

      
          const { error } = await supabase
            .from('quotes')
            .insert(formData);
      
          if (error) {
            console.log(supabaseKey);
            console.error('Error adding quote:', error);
            // Handle errors appropriately (e.g., display error message to user)
          } else {
            console.log('quote added successfully!');
            // Clear the form or display a success message (optional)
            alert('Quote added successfully!');
            setFormData({
              first: '',
              last: '',
              email: '',
              phone: '',
              proposal_text: '',
              mbudget: '',
              lbudget: '',
            });
          }
        };

  // Add your component logic and JSX content here
  return (
    <div>
       {/*Page Title */}
  <section className="row py-2 justify-content-center d-flex ">
  
      <div className="col-12 text-d">
        
            <h1 className='title-f'>Contact Us</h1>
        
      </div>

  </section>


     
     {/* Contact Us Section */}
     <div className="container">
      <div className="row ">
        <p className='text-c'>By adding a proposal you will get presidence over other forms of contact, <br/> We will get back to you promptly!</p>
      </div>
    <h1 className="form-title title-f">Get your Free Quote Today</h1>



{/*-----------------------------------------------------------------------------Form------------------------------------------------------------ */}
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-2 col-xs-1 "/>
          <div className="col-md-4 col-xs-12">
            <div className="mb-3">
              <label htmlFor="firstName" className="form-label heading-new">First Name</label>
              <input type="text" className="form-control"  name="first" value={formData.first} onChange={handleChange}id="firstName" required placeholder='first'/>
            </div>
            <div className="mb-3">
              <label htmlFor="lastName" className="form-label">Last Name</label>
              <input type="text" className="form-control" name="last"  value={formData.last} onChange={handleChange} id="lastName" required placeholder='last'/>
            </div>
            <div className="mb-3">
              <label htmlFor="phone" className="form-label">Phone Number</label>
              <input type="tel" className="form-control" name="phone" value={formData.phone} onChange={handleChange} id="phone" required placeholder='(xxx)-xxx-xxxx'/>
            </div>
            <div className="mb-3"> 
              <label htmlFor="email" className="form-label"  >Email Address</label>
              <input type="email" className="form-control" name="email"  value={formData.email} onChange={handleChange} id="email" required placeholder='example@example.com' />
            </div>
          </div>
          <div className='col-md-4 col-xs-12 prop'>
            <label htmlFor="Proposal" className="form-label">Question or Job Proposal</label>
            <input type="text" className="form-control drop-b" id="proposal"  name="proposal_text" value={formData.proposal_text} onChange={handleChange} required placeholder='Question or Proposal'/>
             
            </div>

            <div className="mb-2 xs-4  match-bud  submit-mar"> 
                  <label htmlFor="mbudget" className="form-label " > Materials Budget </label>
                  <input type="text" className="form-control "  name="mbudget" value={formData.mbudget} onChange={handleChange} id="mbudget" required placeholder='$USD budget amount' />
                </div> 
                <div className="mb-3 submit-mar"> 
                  <label htmlFor="lbudget" className="form-label "  >Labor Budget</label>
                  <input type="text" className="form-control" name="lbudget"  value={formData.lbudget} onChange={handleChange} id="lbudget" required placeholder='$USD budget amount' />
            </div>
                
          <div className="col-md-2 col-xs-1 "/>
             
        </div>
              <div className='col-md-2 col-xs-1 '></div>

        <div className='row py-1'>
          <div className='col-2'></div>
          <div className='col-8 content-justify-center marg-sub'>
        <button type="submit" id='submit' className="btn btn-primary wid-cen" >Submit</button>
        </div>
        <div className='col-2'></div>
        </div>
      </form>
{/*-----------------------------------------------------------------------------Form------------------------------------------------------------ */}




      
    </div>

  </div>
  );
};

export default Contact;