import React from 'react';
import "./documents.css";

const Document = () => {
  // Add your component logic and JSX content here
  return (
    <>
     {/*Page Title */}
  <div className="row justify-content-center d-flex">
    `    
        <div className="col-12">
          
              <h1 className='title-f'>Documents And Info</h1>
          
        </div>

      </div>`


    <div container >
      <div className='row py-5 margin-space bor-change'>
          <h1>(general)</h1>

          <h1>Home Repair Service Contract</h1><br/>
          <div className='row py-1 margin-space'></div>

          <p className='text-left'>This contract is made and entered into on (DATE)_____________ and between:

          Russell and Christian Drake residing at [Contractor Address]_________________________, ("Contractor")
          [name]_______________ residing at [Customer Address] ______________________, ("Customer")
          <br/>
            <h2>Project Description:</h2>
          <br/>

          The Contractor agrees to perform __________ services for the Customer at the property located at [Customer Address] ____________________. The specific repairs to be performed are as follows:
          <br/>

          ---------------------------------[Materials Estimate upfront Charge]---------------------------------
          <br/>
              <h2>Estimates:</h2>
          <br/>
          The Contractor provides free estimates for standard home repair jobs.
          For very large or complex projects, the Contractor may charge a consultation fee to provide a detailed estimate.
          Estimates are not final and may be adjusted due to unforeseen circumstances encountered during the project. Any adjustments will be communicated to the Customer for approval before proceeding.
          <br/>
              <h2>Payments:</h2>
          <br/>
          The total cost of the project is estimated at [Estimated Cost] ___________.
          The Customer agrees to pay a down payment of [Down Payment Amount]  ____________  upon signing this contract.
          <br/>
          The remaining balance will be due [Payment Schedule, e.g., upon completion of the project].
          The Customer agrees to pay late fees of [Late Fee Percentage] for any payment received more than [Number] days past the due date.
          <br/>
              <h2>Changes and Additional Work:</h2>
          <br/>
          Any changes to the scope of work must be agreed upon by both parties in writing before the Contractor proceeds.
          Change orders will be priced based on [Pricing Method for Changes, e.g., hourly rate for labor, material cost + markup].
          <br/>
          The Contractor is not obligated to perform additional work not included in the original contract or approved change orders.
          
          <br/>
              <h2>Warranties and Guarantees:</h2>
          <br/>

          The Contractor warrants that all work will be performed in a professional and workmanlike manner according to industry standards.
          The Contractor warrants all materials and labor for a period of [Warranty Period, e.g., one year] against defects.
          <br/>
          This warranty covers [What the Warranty Covers, e.g., defects in workmanship or materials].
          <br/>
              <h2>Dispute Resolution:</h2>
          <br/>
          In the event of a dispute arising from this contract, the parties agree to attempt to resolve it amicably through discussion.
          If an amicable resolution cannot be reached, [Dispute Resolution Method, e.g., mediation or arbitration] will be used.
          <br/>
              <h2>Termination:</h2>
          <br/>
          This contract may be terminated by either party upon written notice for [Notice Period] in the event of [Termination Conditions, e.g., non-payment, breach of contract].
          Insurance:
          <br/>
          The Contractor certifies that they carry general liability insurance. (Add worker's compensation insurance if applicable)
          Signatures:
          <br/>
          By signing below, both parties agree to all the terms and conditions set forth in this contract.
          <br/>

          *All customers must sign this contract or equivalent form of service contract agreement.*
          <br/><h3>
          Contractor Signature: _____________________   Date______________________________________
           <br/> Customer Signature:_____________________
          
          Date______________________________________     
          </h3>
       </p>
       </div>
    </div>
    </>
  );
};

export default Document;