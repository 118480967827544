
import React, {useState, useLayoutEffect,} from "react";
import Greenwood from "./assets/Greenwood.png";
import "./home.css";
import Beforebathroom from "../pictures/beforecrapbathroom0724.jpg";
import Afterbathroom from "../pictures/Aftercrapbathroom0724.jpg";
import Beforevent from "../pictures/beforecuttingout0724.jpg";



// i could adsd another modal on startup but would need to 
//be rendered by the session and grab users email and phonenumber."



const Home = () =>{

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalnumber, setmodalnumber] = useState(0);
  

  const handleOpenModal1 = () => {
    setIsModalOpen(true);
    setmodalnumber(1);
  };
  const handleOpenModal2 = () => {
    setIsModalOpen(true);
    setmodalnumber(2);
  };
  const handleOpenModal3 = () => {
    setIsModalOpen(true);
    setmodalnumber(3);
  };
  const handleOpenModal4 = () => {
    setIsModalOpen(true);
    setmodalnumber(4)
  };
  const handleOpenModal5 = () => {
    setIsModalOpen(true);
    setmodalnumber(5)
  };
  const handleOpenModal6 = () => {
    setIsModalOpen(true);
    setmodalnumber(6)
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setmodalnumber(0)
  };
  



  useLayoutEffect(() => {  
  }, []);

  const images = [Beforebathroom,Afterbathroom,Beforevent];


  return (
   

    <div>
           
      {/*--------------------------------- top of center ---------------------*/}





      {/*------------------------------------------------------------------banner------------------------------------------------*/}
      <div className="row">
          <div className="col-12 banner-container ">
            <div className="banner large-screen-only">
              <div className="container move-right d-flex large-screen-only">
              
                <div className="">
                  {/*
                <img classname="banner-image custom-rounded-squarebanner" alt="well image finished" src={wellAfter}/>
                  */}
                <h3 className="banner-text large-screen-only"> ------------ Local | Affordable | Dependable        ------      20% off on Faucet and Toilet installations or repairs </h3>
                </div>
                
              
              </div>
            </div>
          </div>
        </div>
{/*------------------------------------------------------------------banner------------------------------------------------*/}



      <div className="row">
        <div className="col-md-2 ">
       
      </div>
      <div className="col-md-8">
      <h1 className="Main-Title left-title3 titlecontainer">
            Some of our Recent Work: 
          </h1>
      </div>
      <div className="col-md-2"></div>
   </div>
          

      <div className="image-section">

        {images.map((image, index) => (
          <div key={index} className="image-container">
            <img src={image} alt={`Image ${index + 1}`}  />
          </div>
        ))}

      </div>


      {/*--------------------------------- top of center ---------------------*/}

      {/*--------------------------------- center the two sides picture and buttons around it   ---------------------*/}
      <div className="row">
        <div className="col-md-4 "></div>
      <div className="col-md-4">
          <h1 className="Main-Title left-title3 titlecontainer">
            Our Services: 
          </h1>
      </div>
      <div className="col-md-4"></div>
   </div>

   <div className="row">
      <div className="col-md-1 "></div>
      <div className="col-md-10">
        <h3>We provide the right touch/expertice on any remodeling, demoltion, repairs, or installations and were open to any job just give us a call or fill out our contact form and we will be in touch. </h3>
      </div>
      <div className="col-md-1 "></div>
   </div>

      <section className="row py-5 services row-padding hide-small">
        <div className="col-md-6 d-flex flex-column col-sm-12 down ">

          <button  type="button" className="info-box mb-m first-a btn " onClick={handleOpenModal1}>
            <h1 className="text-grey first-h drop">
              Plumbing
            </h1>
          </button>         

          <div className="info-box mb-m second-a btn info-phone hide-small"onClick={handleOpenModal2}>
            <h1 className="text-grey">Electrical
            </h1>
          </div>

          <div className="info-box mb-m third-a btn info-phone hide-small"onClick={handleOpenModal3}>
            <h1 className="text-grey third-h under-text">
              Roofing and Flooring
            </h1>
          </div>

          <div className="info-box mb-m third-a btn info-phone hide-small"onClick={handleOpenModal4}>
            <h1 className="text-grey third-h under-text">
              Carpentry (Interior/Exterior)
            </h1>
          </div>

          <div className="info-box mb-m second-a btn info-phone hide-small " onClick={handleOpenModal5}>
            <h1 className="text-grey second-h under-text">Installations</h1>
          </div>

          <div className="info-box mb-m first-a btn info-phone hide-on-small-screens" onClick={handleOpenModal6}>
            <h1 className="text-grey first-h">Drywall and Painting</h1>
          </div>
        </div>

        <div className="col-md-6 col-sm-12 order-md-1">
          <img
            src={Greenwood}
            alt="The right Touch title Logo"
            className="img-fluid  h-60 w-60 mx-auto circular-border"
          />
        </div>
      </section>
      {/*--------------------------------- center the two sides  ---------------------*/}




      {/*--------------------------------- Phone screen  ---------------------*/}
      <section className="row py-5 services row-padding hide-large ">
        <div className="col-md-6 d-flex flex-column col-sm-12 down ">

          <div className="info-box mb-m btn info-phone " onClick={handleOpenModal1} >
            <h1>
              Plumbing
            </h1>
          </div>

            <div className="info-box mb-m  btn info-phone"onClick={handleOpenModal2}>
              <h1 className="text-grey ">
                Electrical
              </h1>
            </div>
          
            <div className="info-box mb-m  btn info-phone "onClick={handleOpenModal3}>
              <h1 className="text-grey ">
                Roofing/Flooring
              </h1>
            </div>
       
            <div className="info-box mb-m  btn info-phone "onClick={handleOpenModal4}>
              <h1 className="text-grey ">
                Carpentry 
              </h1>
            </div>
          
            <div className="info-box mb-m  btn info-phone " onClick={handleOpenModal5}>
              <h1 className="text-grey ">Installations
              </h1>
            </div>
         
            <div className="info-box mb-m  btn info-phone " onClick={handleOpenModal6}>
              <h1 className="text-grey ">Drywall and Paint
              </h1>
            </div>

            <div className="col-md-6 col-sm-12 order-md-1">
              <img
                src={Greenwood}
                alt="The right Touch title Logo"
                className="img-fluid  h-60 w-60 mx-auto circular-border"
              />
            </div>
    
        </div>
      </section>
      {/*--------------------------------- phone screens  ---------------------*/
      /*-Idea!!!!- what if we used them at 40 percent margin and then on hover they would slide right animation and have a dropdown
      this dropdown would show the typical prices of services in this type. -*/
      }

      {/* -----------------------------------About Us Section----------------------------  */}
      <section className="row py-5 about-us border-bot margin-new ">
        <div className="col-md-8 mx-auto align-left ">
          <h2>About Us</h2> {/* Replace with your About Us heading */}
          <p>
            Established in Greenwood County by Russell Drake and Christian
            Drake, The Right Touch brings a combined leadership of experience
            and dedication to the industry.
          </p>
          <h3> Dedication to Quality and Customer Satisfaction:</h3>
          <p>
            At The Right Touch, we believe in going the extra mile to ensure our
            customers receive exceptional service and a job done right, every
            time. Professionalism and Expertise: Our commitment to maintaining a
            clean and professional work environment translates into highly
            skilled and experienced personnel dedicated to delivering expert
            solutions.
          </p>
          <p>
            These core principles have helped us to become that company that we
            are today.
            <br />
            If you need to contact us or have any concerns please dont hesitate
            to contact us directly or leave an email.
          </p>
          <p>
            A Commitment to Excellence: We are passionate about exceeding
            expectations and building trust with our clients. We achieve this by
            focusing on a commitment to excellence, one project at a time.
            Building Trust Through Collaboration: We understand that open
            communication and collaboration are key to success. We work closely
            with our clients to ensure we meet their needs and deliver the
            results they deserve. .
          </p>
        </div>
      </section>
      {/* -----------------------------------About Us Section----------------------------  */}


      {/*------------------------------------------------------------------ Modal Popup section--------------------------------------------------------------------------- */}


      { isModalOpen && (
        <div className="modal-overlay">
           <div>{console.log(modalnumber)}</div>
          <div className="modal-content">
              {/* ... */}
            {modalnumber === 1 && (
                <div>
                    <div className="modal-footer">
                                      <button type="button" className="btn btn-flex btn-warning" onClick={handleCloseModal} > EXIT</button>
                    </div>  
                        <h1 className="u-title">Plumbing Services</h1>

                                    <div className=" text-left">
                                      <h4 className="text-left">Well pump Replacements:</h4> 
                                      <p>Well pump Replacements are a vital part for homeowners if they have one and are very important for areas where city water is not as 
                                        easily available.Our team is dedicated to providing you well pump replacement services Typically pumps range from $300 to $650 depending 
                                        on the type previously installed and on your preferences.
                                      <br/></p>
                                      <p className="text-primary">Averavge Overall Price: $800 - $1400
                                        <br/></p>

                                      <h4 className="text-left">Faucet Repair Or Installations:</h4> 
                                      <p> Leaky or malfunctioning faucets are a frequent issue. Replacing a faucet cartridge or the entire faucet is a common fix using PVC for the supply lines.
                                      Installations However, will require the faucet and other materials depending on the type and complexity of faucet.
                                      <br/></p>
                                        <p className="text-primary">Averavge in SC: $75 - $400
                                        <br/></p>
                                      <h4>Toilet Repair:</h4> <p>Running toilets, clogged toilets, and faulty flappers are widespread problems. Repairs often involve replacing parts within the toilet tank or connecting new supply lines with PVC.
                                      <br/></p>
                                        <p className="text-primary">Averavge in SC: $120 - $340
                                        <br/></p>
                                      <h4>Sink Drain Clog:</h4><p> Clogged kitchen and bathroom drains are a regular occurrence. Occasionally these are easy to locate and fix but sometimes require a plumbing snake, chemicals, or to pysically remove the clog.
                                      <br/></p>
                                        <p className="text-primary">Averavge in SC: $100 - $300
                                        <br/></p>
                                      <h4>Showerhead/Tub Faucet Repair:</h4><p> Replacing a showerhead or tub faucet due to leaks, drips, or malfunctioning parts is a common job. PVC pipes are typically used for the shower or tub supply lines.
                                      <br/></p>
                                        <p className="text-primary">Averavge in SC: $150 - $300 
                                        <br/></p>
                                      <h4>Washing/drying Machine Installation or Replacement:</h4> <p>Worn-out or leaky washing machine hoses need to be replaced to prevent potential flooding. These hoses often connect to PVC supply lines.
                                      <br/></p>
                                        <p className="text-primary">Averavge in SC: $75 - $150
                                        <br/></p>
                                      <h4>Garbage Disposal Replacement/Installation:</h4><p> A jammed or malfunctioning garbage disposal might require repair or replacement. May require additional PVC or other lines.
                                      <br/></p>
                                        <p className="text-primary">Averavge in SC: $100 - $200 
                                        <br/></p>
                                      <h4>Pipe Leak Repair:</h4> <p>Leaks in PVC pipes under sinks, behind toilets, or in crawl spaces need to be identified and repaired using appropriate PVC fittings and patching techniques.
                                      </p>
                                      <p className="text-primary">
                                        
                                        Averavge in SC: $150 - $400
                                        <br/>

                                        Crawlspace fee: $100
                                      <br/></p>
                                      <h4>Water Heater Installation</h4><p> Full installation and hook up of water lines, includes disposal of old water heater but does not include water heater itself.
                                      </p>
                                      <p className="text-primary">Averavge in SC: $250 - $350
                                      <br/></p>
                                    </div>
       
                </div>
            )}
            {modalnumber === 2 && (
                <div>
                    <div className="modal-footer">
                                      <button type="button" className="btn btn-flex btn-warning" onClick={handleCloseModal} > EXIT</button>
                    </div>

                        <h1 className="u-title">Electrical</h1>
                                    <div className=" text-left"><h4 className="text-left">Ceiling Fan Installation:</h4> <p>Add style and comfort to your home with a new ceiling fan. We can safely install your fan and ensure proper electrical wiring.
                                        <br/></p>
                                        <p className="text-primary">Averavge in SC: $75 - $200 
                                        <br/></p>
                                      <h4>Light Switch & Outlet Repair:</h4> <p>Faulty switches and outlets can be frustrating and pose a safety hazard. Our electricians can diagnose the problem and make the necessary repairs to get your electrical components functioning properly again. 
                                        <br/></p>
                                        <p className="text-primary">Averavge in SC: $75 - $200 
                                        <br/></p>
                                      <h4>Light Fixture Replacement:</h4><p> Update the look of your space with new light fixtures. Our electricians can install any type of fixture, from recessed lighting to pendants. 
                                        <br/></p>
                                        <p className="text-primary">Averavge in SC: $75 - $200 
                                        <br/></p>
                                      <h4>GFCI Outlet Installation:</h4><p>Protect yourself from electrical shock in kitchens, bathrooms, and garages with the installation of GFCI outlets. These outlets interrupt the flow of electricity in case of a ground fault.
                                        <br/></p>
                                        <p className="text-primary">Averavge in SC: $75 - $200 
                                        <br/></p>
                                      <h4>Thermostat Installation</h4><p> A jammed or malfunctioning garbage disposal might require repair or replacement. PVC pipes are typically used for the drain line.
                                        <br/></p>
                                        <p className="text-primary">Averavge in SC: $75 - $200 
                                        <br/></p>
                                      
                                    </div>
                                    
                                    
                         
                </div>
            )}
            {modalnumber === 3 && (
                <div>
                    <div className="modal-footer">
                                      <button type="button" className="btn btn-flex btn-warning" onClick={handleCloseModal} > EXIT</button>
                    </div>  
                    {/* average prices in sc per square footage https://www.fixr.com/costs/mobile-home-roof-replacement */}
                        <h1 className="u-title">Roofing and Flooring</h1>
                                    <div className=" text-left">
                                      <h4 className="text-left">Quality Roof Replacements:</h4> 
                                      <p>  Is your roof nearing the end of its lifespan? We offer complete roof replacements with high-quality materials, ensuring your home is secure for years to come.
                                        <br/></p>
                                        <p className="">Free Consultation & Quote Required (average price varies depending on size, roof pitch, materials chosen)</p>
                                        <p className="text-primary">Averavge in SC: $4,000 - $10,000 
                                        <br/></p>
                                      <h4>Toilet Repair:</h4> 
                                      <p>Running toilets, clogged toilets, and faulty flappers are widespread problems. Repairs often involve replacing parts within the toilet tank or connecting new supply lines with PVC.
                                        <br/></p>
                                      <h4>Sink Drain Clog:</h4>
                                      <p> Clogged kitchen or bathroom sink drains are a regular occurrence. Plumbers might use a snake or hydro jetting to clear the clog and may need to access PVC drain lines underneath the sink.
                                        <br/></p>
                                      <h4>Showerhead/Tub Faucet Repair:</h4>
                                      <p> Replacing a showerhead or tub faucet due to leaks, drips, or malfunctioning parts is a common job. PVC pipes are typically used for the shower or tub supply lines.
                                        <br/></p>
                                      <h4>Washing Machine Hose Replacement:</h4> 
                                      <p>Worn-out or leaky washing machine hoses need to be replaced to prevent potential flooding. These hoses often connect to PVC supply lines.
                                        <br/></p>
                                      <h4>Garbage Disposal Repair or Replacement:</h4>
                                      <p> A jammed or malfunctioning garbage disposal might require repair or replacement. PVC pipes are typically used for the drain line.
                                        <br/></p>
                                      <h4>Pipe Leak Repair:</h4> <p>Leaks in PVC pipes under sinks, behind toilets, or in crawl spaces need to be identified and repaired using appropriate PVC fittings and patching techniques.
                                        <br/></p>
                                    </div>
                                    <h1>Estimated Labor Costs</h1>
                                    
                                    
                                    <div className=" text-left "><h4>Faucet Repair:</h4> <p className="text-primary">$75 - $200 
                                        <br/></p><h4> Toilet Repair:</h4><p className="text-primary"> $100 - $300
                                        <br/></p><h4>Sink Drain Clog:</h4> <p className="text-primary">$100 - $250
                                        <br/></p><h4>Showerhead/Tub Faucet Repair:</h4><p className="text-primary"> $150 - $300
                                        <br/></p><h4>Washing Machine Hose Replacement:</h4> <p className="text-primary">$75 - $150
                                        <br/></p><h4>Garbage Disposal Repair:</h4> <p className="text-primary">$100 - $200</p><p> (may be higher for replacement)
                                        <br/></p><h4>Pipe Leak Repair:</h4> <p className="text-primary">$150 - $400 </p> (depending on the complexity of the leak and access)
                                    </div>
                </div>
            )}{modalnumber === 4 && (
              <div>
                  <div className="modal-footer">
                                    <button type="button" className="btn btn-flex btn-warning" onClick={handleCloseModal} > EXIT</button>
                  </div>  
                      <h1 className="u-title">Carpentry</h1>
                      
                                  <div className=" text-left"><h4 className="text-left">Faucet Repair:</h4> <p> Leaky or malfunctioning faucets are a frequent issue. Replacing a faucet cartridge or the entire faucet is a common fix using PVC for the supply lines.
                                    <br/></p><h4>Toilet Repair:</h4> <p>Running toilets, clogged toilets, and faulty flappers are widespread problems. Repairs often involve replacing parts within the toilet tank or connecting new supply lines with PVC.
                                    <br/></p><h4>Sink Drain Clog:</h4><p> Clogged kitchen or bathroom sink drains are a regular occurrence. Plumbers might use a snake or hydro jetting to clear the clog and may need to access PVC drain lines underneath the sink.
                                    <br/></p><h4>Showerhead/Tub Faucet Repair:</h4><p> Replacing a showerhead or tub faucet due to leaks, drips, or malfunctioning parts is a common job. PVC pipes are typically used for the shower or tub supply lines.
                                    <br/></p><h4>Washing Machine Hose Replacement:</h4> <p>Worn-out or leaky washing machine hoses need to be replaced to prevent potential flooding. These hoses often connect to PVC supply lines.
                                    <br/></p><h4>Garbage Disposal Repair or Replacement:</h4><p> A jammed or malfunctioning garbage disposal might require repair or replacement. PVC pipes are typically used for the drain line.
                                    <br/></p><h4>Pipe Leak Repair:</h4> Leaks in PVC pipes under sinks, behind toilets, or in crawl spaces need to be identified and repaired using appropriate PVC fittings and patching techniques.
                                  </div>
                                  {/*
                                  <h1>Estimated Labor Costs</h1>
                                  
                                  
                                  <div className=" text-left "><h4>Faucet Repair:</h4> <p className="text-primary">$75 - $200 
                                      <br/></p><h4> Toilet Repair:</h4><p className="text-primary"> $100 - $300
                                      <br/></p><h4>Sink Drain Clog:</h4> <p className="text-primary">$100 - $250
                                      <br/></p><h4>Showerhead/Tub Faucet Repair:</h4><p className="text-primary"> $150 - $300
                                      <br/></p><h4>Washing Machine Hose Replacement:</h4> <p className="text-primary">$75 - $150
                                      <br/></p><h4>Garbage Disposal Repair:</h4> <p className="text-primary">$100 - $200</p><p> (may be higher for replacement)
                                      <br/></p><h4>Pipe Leak Repair:</h4> <p className="text-primary">$150 - $400 </p> (depending on the complexity of the leak and access)
                                  </div>
                                  */}
              </div>
          )}{modalnumber === 5 && (
            <div>
                <div className="modal-footer">
                                  <button type="button" className="btn btn-flex btn-warning" onClick={handleCloseModal} > EXIT</button>
                </div>  
                    <h1 className="u-title">Randomness</h1>
                    
                                <div className=" text-left"><h4 className="text-left">Faucet Repair:</h4> <p> Leaky or malfunctioning faucets are a frequent issue. Replacing a faucet cartridge or the entire faucet is a common fix using PVC for the supply lines.
                                  <br/></p><h4>Toilet Repair:</h4> <p>Running toilets, clogged toilets, and faulty flappers are widespread problems. Repairs often involve replacing parts within the toilet tank or connecting new supply lines with PVC.
                                  <br/></p><h4>Sink Drain Clog:</h4><p> Clogged kitchen or bathroom sink drains are a regular occurrence. Plumbers might use a snake or hydro jetting to clear the clog and may need to access PVC drain lines underneath the sink.
                                  <br/></p><h4>Showerhead/Tub Faucet Repair:</h4><p> Replacing a showerhead or tub faucet due to leaks, drips, or malfunctioning parts is a common job. PVC pipes are typically used for the shower or tub supply lines.
                                  <br/></p><h4>Washing Machine Hose Replacement:</h4> <p>Worn-out or leaky washing machine hoses need to be replaced to prevent potential flooding. These hoses often connect to PVC supply lines.
                                  <br/></p><h4>Garbage Disposal Repair or Replacement:</h4><p> A jammed or malfunctioning garbage disposal might require repair or replacement. PVC pipes are typically used for the drain line.
                                  <br/></p><h4>Pipe Leak Repair:</h4> Leaks in PVC pipes under sinks, behind toilets, or in crawl spaces need to be identified and repaired using appropriate PVC fittings and patching techniques.
                                </div>
                                {/*
                                <h1>Estimated Labor Costs</h1>
                                
                                
                                <div className=" text-left "><h4>Faucet Repair:</h4> <p className="text-primary">$75 - $200 
                                    <br/></p><h4> Toilet Repair:</h4><p className="text-primary"> $100 - $300
                                    <br/></p><h4>Sink Drain Clog:</h4> <p className="text-primary">$100 - $250
                                    <br/></p><h4>Showerhead/Tub Faucet Repair:</h4><p className="text-primary"> $150 - $300
                                    <br/></p><h4>Washing Machine Hose Replacement:</h4> <p className="text-primary">$75 - $150
                                    <br/></p><h4>Garbage Disposal Repair:</h4> <p className="text-primary">$100 - $200</p><p> (may be higher for replacement)
                                    <br/></p><h4>Pipe Leak Repair:</h4> <p className="text-primary">$150 - $400 </p> (depending on the complexity of the leak and access)
                                </div>
                                */}
            </div>
        )}{modalnumber === 6 && (
          <div>
              <div className="modal-footer">
                                <button type="button" className="btn btn-flex btn-warning" onClick={handleCloseModal} > EXIT</button>
              </div>  
                  <h1 className="u-title">Randomness</h1>
                  
                              <div className=" text-left"><h4 className="text-left">Faucet Repair:</h4> <p> Leaky or malfunctioning faucets are a frequent issue. Replacing a faucet cartridge or the entire faucet is a common fix using PVC for the supply lines.
                                <br/></p><h4>Toilet Repair:</h4> <p>Running toilets, clogged toilets, and faulty flappers are widespread problems. Repairs often involve replacing parts within the toilet tank or connecting new supply lines with PVC.
                                <br/></p><h4>Sink Drain Clog:</h4><p> Clogged kitchen or bathroom sink drains are a regular occurrence. Plumbers might use a snake or hydro jetting to clear the clog and may need to access PVC drain lines underneath the sink.
                                <br/></p><h4>Showerhead/Tub Faucet Repair:</h4><p> Replacing a showerhead or tub faucet due to leaks, drips, or malfunctioning parts is a common job. PVC pipes are typically used for the shower or tub supply lines.
                                <br/></p><h4>Washing Machine Hose Replacement:</h4> <p>Worn-out or leaky washing machine hoses need to be replaced to prevent potential flooding. These hoses often connect to PVC supply lines.
                                <br/></p><h4>Garbage Disposal Repair or Replacement:</h4><p> A jammed or malfunctioning garbage disposal might require repair or replacement. PVC pipes are typically used for the drain line.
                                <br/></p><h4>Pipe Leak Repair:</h4> Leaks in PVC pipes under sinks, behind toilets, or in crawl spaces need to be identified and repaired using appropriate PVC fittings and patching techniques.
                              </div>
                              {/*
                              <h1>Estimated Labor Costs</h1>
                              
                              
                              <div className=" text-left "><h4>Faucet Repair:</h4> <p className="text-primary">$75 - $200 
                                  <br/></p><h4> Toilet Repair:</h4><p className="text-primary"> $100 - $300
                                  <br/></p><h4>Sink Drain Clog:</h4> <p className="text-primary">$100 - $250
                                  <br/></p><h4>Showerhead/Tub Faucet Repair:</h4><p className="text-primary"> $150 - $300
                                  <br/></p><h4>Washing Machine Hose Replacement:</h4> <p className="text-primary">$75 - $150
                                  <br/></p><h4>Garbage Disposal Repair:</h4> <p className="text-primary">$100 - $200</p><p> (may be higher for replacement)
                                  <br/></p><h4>Pipe Leak Repair:</h4> <p className="text-primary">$150 - $400 </p> (depending on the complexity of the leak and access)
                              </div>
                              */}
          </div>
      )}{modalnumber === 7 && (
        <div>
            <div className="modal-footer">
                              <button type="button" className="btn btn-flex btn-warning" onClick={handleCloseModal} > EXIT</button>
            </div>  
                <h1 className="u-title">Randomness</h1>
                
                            <div className=" text-left"><h4 className="text-left">Faucet Repair:</h4> <p> Leaky or malfunctioning faucets are a frequent issue. Replacing a faucet cartridge or the entire faucet is a common fix using PVC for the supply lines.
                              <br/></p><h4>Toilet Repair:</h4> <p>Running toilets, clogged toilets, and faulty flappers are widespread problems. Repairs often involve replacing parts within the toilet tank or connecting new supply lines with PVC.
                              <br/></p><h4>Sink Drain Clog:</h4><p> Clogged kitchen or bathroom sink drains are a regular occurrence. Plumbers might use a snake or hydro jetting to clear the clog and may need to access PVC drain lines underneath the sink.
                              <br/></p><h4>Showerhead/Tub Faucet Repair:</h4><p> Replacing a showerhead or tub faucet due to leaks, drips, or malfunctioning parts is a common job. PVC pipes are typically used for the shower or tub supply lines.
                              <br/></p><h4>Washing Machine Hose Replacement:</h4> <p>Worn-out or leaky washing machine hoses need to be replaced to prevent potential flooding. These hoses often connect to PVC supply lines.
                              <br/></p><h4>Garbage Disposal Repair or Replacement:</h4><p> A jammed or malfunctioning garbage disposal might require repair or replacement. PVC pipes are typically used for the drain line.
                              <br/></p><h4>Pipe Leak Repair:</h4> Leaks in PVC pipes under sinks, behind toilets, or in crawl spaces need to be identified and repaired using appropriate PVC fittings and patching techniques.
                            </div>
                            {/*
                            <h1>Estimated Labor Costs</h1>
                            
                            
                            <div className=" text-left "><h4>Faucet Repair:</h4> <p className="text-primary">$75 - $200 
                                <br/></p><h4> Toilet Repair:</h4><p className="text-primary"> $100 - $300
                                <br/></p><h4>Sink Drain Clog:</h4> <p className="text-primary">$100 - $250
                                <br/></p><h4>Showerhead/Tub Faucet Repair:</h4><p className="text-primary"> $150 - $300
                                <br/></p><h4>Washing Machine Hose Replacement:</h4> <p className="text-primary">$75 - $150
                                <br/></p><h4>Garbage Disposal Repair:</h4> <p className="text-primary">$100 - $200</p><p> (may be higher for replacement)
                                <br/></p><h4>Pipe Leak Repair:</h4> <p className="text-primary">$150 - $400 </p> (depending on the complexity of the leak and access)
                            </div>
                            */}
        </div>
    )}


    {/*------------------------------------------------------------------ Modal Popup section--------------------------------------------------------------------------- */}

          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
