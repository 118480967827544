import React from 'react';
import "./navbar.css";
import Title from "./titlered.png";
import "./navbar.css";
import HouseLogo from "./houselogo2.png";



const Navbar = () => {
 
  // Add your component logic and JSX content here
  return (

    <div>
      <section>
       

            <div className="row py-3  align-items-center bg-dark text-white d-flex">
                    <div className='col-md-4 col-sm-12 d-flex' >
                      <img src={HouseLogo} alt="The right Touch Logo" className="img-logo m-md-30 m-xs-30 mx-auto"/>
                    </div>
                    <div className="col-md-4 col-sm-12 d-flex "> 
                        <img src={Title} alt="The right Touch Title Logo" className="img-fluid m-md-30 m-md-30 mx-auto bg-logo" />
                         
                    </div>
                    <div className='col-md-4 col-sm-12 hide-on-small-screens'>

                    <a href="/quote" className='btn btn-warning btn-border text-quote title-g' > Start Your Project Today:<br></br> Get a Free Quote</a>

                    </div>
                    <div className='col-md-4 col-sm-12 hide-on-large-screens cent-cvh'>

                    <a href="/contact" className='btn btn-warning btn-border text-quote title-g margin-new' > Start Your Project Today:<br></br> Get a Free Quote</a>

                    </div>

            </div>
      </section>


                
      <header className="row align-items-center py-3 bg-dark bg-bor  nav-bar hide-on-small-screens">


          {/* ------------------------Only on large Screens --------------------- */}
          <div className="row  hide-on-small-screens cent-cvh ">
            <div className="col-md-4 col-sm-12 text-center hide-on-small-screens ">
            
                    <a href="/document" className="btn  color-btn">
                    Documents
                  </a>
      
                
                </div>
                <div className="col-md-4 col-sm-12 text-center hide-on-small-screens">
                    <a href="/examples" className="btn  color-btn ">
                      Project Portfolio
                    </a>
                </div>
                <div className="col-md-2 col-sm-12 text-center hide-on-small-screens ">
                    <a href="/contact" className="btn color-btn">
                      Contact Info
                    </a>
                    
               
               </div>
          <div className="col-md-2 col-sm-12 text-center hide-on-small-screens">
            <a href="/" className="btn color-btn home">
                    Home
                  </a>
          </div>
          </div>
          </header>
          {/* ------------------------Only on large Screens --------------------- */}


          {/* ------------------------Only on small Screens --------------------- */}
          <header className="row align-items-center py-3 bg-dark bg-bor nav-barbghoul hide-on-large-screens">
          <div className="row py-4 hide-on-large-screens cent-cvh">
          
            <div className="row  cent-cvh">
              
                    <a href="/examples" className="btn color-btn">
                              Project Examples
                    </a> 
                    </div>
              
              <div className="row  cent-cvh">
               
                    <a href="/contact" className="btn color-btn ">
                      Contact Info
                    </a>
                    
                    </div>
                    <div className="row  cent-cvh">
                      
                    <a href="/document" className="btn color-btn ">
                      Free Estimate
                    </a>
                    
                    </div>
                    <div className="row  cent-cvh">
                      
                    <a href="/" className="btn color-btn ">
                        Home
                    </a>
                    
                    </div>
            

          </div>
          {/* ------------------------Only on small Screens --------------------- */}
        </header>

     </div>
  );
};

export default Navbar;