import React from 'react';
import "./projects.css";
import Front from "../pictures/FrontView-youngs.jpg"; 
import Back from "../pictures/BackView-youngs.jpg";
import Young from "../pictures/YoungView-youngs.jpg";
import Window from "../pictures/WindowReplacement.jpg";
import Bath from "../pictures/batthroomFloor.jpg";
import Finished from "../pictures/FinishedFlooring.jpg";
import Septic from "../pictures/septic tank.jpg";
import Running from "../pictures/RunningLine.jpg";
import Beforebathroom from "../pictures/beforecrapbathroom0724.jpg";
import Afterbathroom from "../pictures/Aftercrapbathroom0724.jpg";
import Beforevent from "../pictures/beforecuttingout0724.jpg";
import Aftervent from "../pictures/aftercuttingout0724.jpg";
import Aftersubfloor from "../pictures/aftersubfloortoiletreplacement0724.jpg";
import Toiletcaulking from "../pictures/toiletcaulking0724.jpg";
import wellAfter from "../pictures/wellfinished.jpg";
import wellBefore from "../pictures/wellbefore.jpg";
import wellcutline from "../pictures/wellcutline.jpg";
import wellPlumbing from "../pictures/wellplumbing.jpg";
import wellLength from "../pictures/WellLength.jpg";




const Projects = () => {

  const alert = () => {
   console.log("pic was pressed");
  }




  // Add your component logic and JSX content here
  return (
   <div>
      {/*--------------------------------- title  ---------------------*/}
    <section className="row justify-content-center d-flex">
  
      <div className="col-12 ">
      
            <h1 className='title-f'>Project Examples</h1>
        
      </div>
 

  </section>
  {/*--------------------------------- title  ---------------------*/}


  {/*--------------------------------- top of center ---------------------*/}
  <section className="row justify-content-center d-flex">
    

    
        <div className='container'>
          
            
            <div className='row py-2'>
              
            <img src={wellAfter} className='col-3 img-fluid mx-auto custom-rounded-square bd-black' alt="This is a pic of young park stairs built by me" onClick={alert}/>
            <img src={wellBefore} className='col-3 img-fluid mx-auto custom-rounded-square bd-black' alt="This is a pic of young park stairs built by me" onClick={alert}/>
            <img src={wellLength} className='col-3 img-fluid mx-auto custom-rounded-square bd-black' alt="This is a pic of young park stairs built by me" onClick={alert}/>
            
          </div> 
          {/*
          <div className='row py-1'>
                    
                    <div className='col-md-3 col-xs-12'>
                        <h3 className='text-center'>New Pump</h3>
                        <p className='text-center'>This is the final well after plumbing electrical and replacement before installing. </p>
                  </div>
                  <div className='col-md-3 col-xs-12 '>
                        <h3 className='text-center'>Old Pump</h3>
                        <p className='text-center'>This is the final well after plumbing electrical and replacement before installing. </p>
                  </div>
                  <div className='col-md-3 col-xs-12 '>
                        <h3 className='text-center'>New Line Replacement</h3>
                        <p className='text-center'>This is the final well after plumbing electrical and replacement before installing. </p>
                  </div>
            </div>
            */}
            <div className='row py-3'>
              
                <img src={Beforebathroom} className='col-3 img-fluid mx-auto custom-rounded-square bd-black' alt="This is a pic of young park stairs built by me" onClick={alert}/>
              
              <img src={Afterbathroom} className='col-3 img-fluid mx-auto custom-rounded-square bd-black' alt="This is a pic of young park stairs built by me" onClick={alert}/>
              <img src={Beforevent} className='col-3 img-fluid mx-auto custom-rounded-square bd-black' alt="This is a pic of young park stairs built by me" onClick={alert}/>
              
            </div> 
            {/*
            <div className='row py-1'>
                    
                    
                    <div className='col-md-32 col-xs-12'>
                        <h3 className='text-center'>New Pump</h3>
                  </div>
                  <div className='col-md-3 col-xs-12 '>
                        <h3 className='text-center'>Old Pump</h3>
                  </div>
                  <div className='col-md-3 col-xs-12 '>
                        <h3 className='text-center'>New Line Replacement</h3>
                  </div>
                
                  
            </div>
            */}
            <div className='row py-3'>
              <img src={Aftervent} className='col-3 img-fluid mx-auto custom-rounded-square bd-black' alt="This is a pic of young park stairs built by me" onClick={alert}/>
              <img src={Toiletcaulking} className='col-3 img-fluid mx-auto custom-rounded-square bd-black' alt="This is a pic of young park stairs built by me" onClick={alert}/>
              <img src={Aftersubfloor} className='col-3 img-fluid mx-auto custom-rounded-square bd-black' alt="This is a pic of young park stairs built by me" onClick={alert}/>
            </div> 
          
            <div className='row py-3'>
              <img src={Running} className='col-3 img-fluid mx-auto custom-rounded-square bd-black' alt="This is a pic of young park stairs built by me" onClick={alert}/>
              <img src={Septic} className='col-3 img-fluid mx-auto custom-rounded-square bd-black' alt="This is a pic of young park stairs built by me" onClick={alert}/>
              <img src={Finished} className='col-3 img-fluid mx-auto custom-rounded-square bd-black' alt="This is a pic of young park stairs built by me" onClick={alert}/>
            </div> 
            <div className='row py-3'>
              <img src={Bath} className='col-3 img-fluid mx-auto custom-rounded-square bd-black' alt="This is a pic of young park stairs built by me" onClick={alert}/>
              <img src={Window} className='col-3 img-fluid mx-auto custom-rounded-square bd-black' alt="This is a pic of young park stairs built by me" onClick={alert}/>
              <img src={Young} className='col-3 img-fluid mx-auto custom-rounded-square bd-black' alt="This is a pic of young park stairs built by me" onClick={alert}/>

            </div> 


            <div className='row py-3'>
              <img src={Front} className='col-3 img-fluid mx-auto custom-rounded-square bd-black' alt="This is a pic of young park stairs built by me" onClick={alert}/>
              <img src={Back} className='col-3 img-fluid mx-auto custom-rounded-square bd-black' alt="This is a pic of young park stairs built by me" onClick={alert}/>
              <img src={Young} className='col-3 img-fluid mx-auto custom-rounded-square bd-black' alt="This is a pic of young park stairs built by me" onClick={alert}/>
            </div>
            <div className='row py-4 '>
              <div>

              </div>
            </div>
        </div>
    
    

    <div className="row py-1"></div>

    
    </section>
    
    </div>
  );
};

export default Projects;