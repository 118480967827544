import { Analytics } from '@vercel/analytics/react';
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Document from "./components/Documents/documents";
import Contact from "./components/contact/contact";
import Projects from "./components/Projects/projects";
import Home from "./components/home/home";
import Navbar from "./components/navbar/navbar";
import Footer from "./components/Footer/Footer";
import PrivacyPolicy from "./components/privacy policy/privacypolicy";
import Quote  from './components/form-quote/quote';





import Helmet from 'react-helmet';
 

function App() {
 
 

  return (
   
       
    
    <div className="App">
      <Helmet>
        <title>Your Page Title</title>
        <meta property="og:title" content="Your Page Title" />
        <meta property="og:description" content="Your Page Description" />
        <meta property="og:image" content="https://path/to/your/image.jpg" />
        <meta property='og:url' content="https://therighttouch-vercel.vercel.app/"/>
      </Helmet>

      <Analytics/>
     
        <BrowserRouter>
        <Navbar/>
        
            <Routes>
              
              <Route path='/' element={<Home/>}/>
                <Route path='/document' element={<Document/>}/>
                <Route path='/examples' element={<Projects/>}/>
                <Route path='/contact' element={<Quote/>}/>
                <Route path='/quote' element={<Contact/>}/>
                <Route path='/privacy' element={<PrivacyPolicy/>}/>                    
            </Routes>
        <Footer/>
        </BrowserRouter>   
</div>
  );
}

export default App;